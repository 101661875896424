import React from "react";

const TypingIndicator = () => {
  return (
    <div className="a-bot-typing-indicator">
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default TypingIndicator;
