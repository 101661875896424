import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import axios from "axios";
import "../App.css";

const Admin = () => {
  const [isLiveSessionActive, setIsLiveSessionActive] = useState(true);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [ipDetails, setIpDetails] = useState(null);
  const [clientURL, setClientURL] = useState(null);
  const chatBoxRef = useRef(null);
  const socket = useRef(null);

  useEffect(() => {
    socket.current = io("https://api.aibot.devateam.com"); // Replace with your server URL
    const handleReceiveMessage = (msg) => {
      if (isLiveSessionActive) {
        setMessages((prevMessages) => [
          { text: msg, sender: "other" },
          ...prevMessages,
        ]);
      }
    };
    socket.current.on("receive_message", handleReceiveMessage);
    return () => {
      socket.current.off("receive_message", handleReceiveMessage);
      socket.current.disconnect();
    };
  }, [isLiveSessionActive]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const sendMessage = () => {
    if (inputMessage.trim() === "" || !isLiveSessionActive) return;
    const messageObject = { text: inputMessage, sender: "self" };
    socket.current.emit("send_message", inputMessage);
    setMessages((prevMessages) => [messageObject, ...prevMessages]);
    setInputMessage("");
  };

  const toggleLiveSession = () => {
    if (isLiveSessionActive) {
      socket.current.emit("disable_session");
      socket.current.disconnect();
      setMessages((prevMessages) => [
        { sender: "system", text: "Live Session Has Been Disabled" },
        ...prevMessages,
      ]);
    } else {
      socket.current.connect();
      socket.current.on("receive_message", (msg) => {
        setMessages((prevMessages) => [
          { text: msg, sender: "other" },
          ...prevMessages,
        ]);
      });
    }
    setIsLiveSessionActive(!isLiveSessionActive);
  };

  // Capture Current URL Connection
  useEffect(() => {
    const fetchVisitDetails = async () => {
      try {
        const response = await axios.get("https://api.aibot.devateam.com/get-visit");
        setClientURL(response.data);
      } catch (error) {
        console.error("Error fetching visit details:", error);
      }
    };

    fetchVisitDetails();
    // Poll every 5 seconds for updates
    const interval = setInterval(fetchVisitDetails, 5000);
    return () => clearInterval(interval); // Cleanup the interval
  }, []);

  // IP Address Connection
  useEffect(() => {
    const fetchIpDetails = async () => {
      try {
        const response = await axios.get("https://api.aibot.devateam.com/get-ip");
        setIpDetails(response.data);
      } catch (error) {
        console.error("Error fetching IP details:", error);
      }
    };
    fetchIpDetails();
    // Poll every 5 seconds for updates
  }, []);
  // Function to get flag emoji from country code
  const getFlagEmoji = (countryCode) => {
    if (!countryCode) return "🏳️"; // Default flag if no country is found
    return countryCode
      .toUpperCase()
      .replace(/./g, (char) =>
        String.fromCodePoint(127397 + char.charCodeAt())
      );
  };
  if (!ipDetails) {
    return <p>Loading IP details...</p>;
  }
  if (ipDetails.message) {
    return <p>{ipDetails.message}</p>;
  }

  return (
    <div className="chat-window bg-secondary bg-gradient vh-100 p-4 d-flex align-items-center justify-content-center">
      <div className="container bg-white h-100 rounded d-flex flex-column py-3">
        <h2 className="a-bot-admin-title">Chat Box</h2>
        <div className="mb-3">
          <div className="a-bot-ip-details">
            <p>From: {clientURL.url}</p>
            <p>IP: {ipDetails.query}</p>
            <p>
              Country: {ipDetails.country} {getFlagEmoji(ipDetails.countryCode)}
            </p>
            <p>City: {ipDetails.city}</p>
            <p>Region: {ipDetails.regionName}</p>
          </div>
          <button
            id="liveSessionBtn"
            className={`btn-live-session ${
              isLiveSessionActive ? "btn-danger" : "btn-success"
            }`}
            onClick={toggleLiveSession}
          >
            {isLiveSessionActive
              ? "Disable Live Session"
              : "Enable Live Session"}
          </button>
        </div>
        <div
          className="chat-box d-flex gap-3 flex-column-reverse my-4 flex-fill"
          ref={chatBoxRef}
        >
          {messages.map((msg, index) => (
            <div
              key={index}
              className={
                msg.sender === "self"
                  ? "from-msg mx-2 d-flex justify-content-end"
                  : "to-msg mx-2 d-flex justify-content-start"
              }
            >
              <span>{msg.text}</span>
            </div>
          ))}
        </div>
        <div className="chat-controls d-flex align-items-stretch p-2">
          <input
            className="form-control border-0 shadow-none"
            id="validationTextarea"
            placeholder="Enter the reply"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                sendMessage();
              }
            }}
          />
          <button className="btn btn-primary border-0" onClick={sendMessage}>
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default Admin;
