import React, { useState, useEffect, useRef } from "react";
import { IoLogoWechat } from "react-icons/io5";
import { RiSendPlane2Fill, RiCustomerServiceFill } from "react-icons/ri";
import { BsHandThumbsUpFill } from "react-icons/bs";
import { BsHandThumbsDownFill } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { io } from "socket.io-client";
import parser from "html-react-parser";
import axios from "axios";
import "../index.css";
import TypingIndicator from "../Components/TypingIndicator";
const apiUrl = process.env.REACT_APP_API_URL;

const Home = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [conversation, setConversation] = useState([]); // Chat history
  const [inputValue, setInputValue] = useState(""); // User input
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state
  const [formLoading, setFormLoading] = useState(false);
  const [isLiveSession, setIsLiveSession] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [socket, setSocket] = useState(null); // Socket instance
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const chatMessagesRef = useRef(null);

  useEffect(() => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [conversation]);
  // const [formMessage, setFormMessage] = useState(""); // Form submission status message
  const submitted =
    sessionStorage.getItem("submitted") === "true" ? true : false;

  // Automatically Open Chat Popup On Page Load
  useEffect(() => {
    const popupTimeout = setTimeout(() => {
      setShowPopup(true);

      // Show the AI bot message 1 second later
      setTimeout(() => {
        const botMessage = {
          sender: "bot",
          text: "How can I help you today?",
        };
        setConversation((prev) => [...prev, botMessage]);
      }, 500);
    }, 2000);
    return () => clearTimeout(popupTimeout);
  }, []);

  // Toggle chat popup visibility
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  // Clean up socket on component unmount
  useEffect(() => {
    return () => {
      if (socket) {
        socket.disconnect();
        console.log("Socket disconnected");
      }
    };
  }, [socket]);

  // Live Session Connection
  const livesession = async () => {
    if (isActive) {
      // Disable live session
      console.log("Disabling Live Session");
      setIsLiveSession(false);
      setIsActive(false);

      // Close socket connection if active
      if (socket) {
        socket.disconnect();
        console.log("Socket disconnected");
        setSocket(null);
      }

      // Update conversation
      setConversation((prev) => [
        ...prev,
        {
          sender: "bot",
          text: "Live Session Has Been Disabled Switiching To Bot.",
        },
      ]);
    } else {
      // Enable live session
      console.log("Live Session");
      setIsLiveSession(true);
      setIsActive(true);

      // Send Email Functionality
      fetch(`${apiUrl}/send-email`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to send details.");
          }
          console.log("Email sent successfully");
          return response;
        })
        .catch((err) => {
          console.error("Error:", err);
        });

      // Add a "Loading..." message to the conversation
      const loadingMessage = {
        sender: "bot",
        text: "Loading...",
      };

      setConversation((prev) => [
        ...prev.filter((msg) => msg.text !== "How can I help you today?"),
        loadingMessage,
      ]);

      // Initialize socket connection
      const liveSocket = io(apiUrl);
      setSocket(liveSocket);
      liveSocket.on("disable_session", () => {
        console.log("Live session disabled by admin");

        // Update the conversation
        setConversation((prev) => [
          ...prev,
          {
            sender: "bot",
            text: "The live session has been disabled. You can now chat with the bot.",
          },
        ]);

        // Disable live session
        setIsLiveSession(false);
        setIsActive(false);

        // Disconnect the socket
        liveSocket.disconnect();
        setSocket(null);
      });

      liveSocket.on("connect", () => {
        console.log("Connected to live session");
      });

      liveSocket.on("receive_message", (msg) => {
        clearTimeout(liveSessionTimeout);
        console.log("Received message:", msg);
        setConversation((prev) => [...prev, { sender: "bot", text: msg }]);
      });

      // Simulate a delay for loading (replace this with actual functionality later)
      setTimeout(() => {
        setConversation((prev) => [
          ...prev.filter((msg) => msg.text !== "Loading..."),
          { sender: "bot", text: "Live session started!" },
        ]);
      }, 2000);

      // Optional timeout to handle session expiry
      const liveSessionTimeout = setTimeout(() => {
        setConversation((prev) => [
          ...prev.filter((msg) => msg.text !== "Loading..."),
          {
            sender: "bot",
            text: "Unfortunately, the live session is not available now. Please fill out the form to connect with our agent later.",
          },
        ]);
      }, 120000); // Form Timeout
    }

    // IP Address Connection
    try {
      const response = await axios.get("http://ip-api.com/json/");
      const ipDetails = response.data;

      // Save IP details to backend
      await axios.post("https://api.aibot.devateam.com/save-ip", ipDetails);
    } catch (error) {
      console.error("Error fetching or saving IP details:", error);
    }

    // Capture Current URL Connection
    try {
      const currentUrl = window.location.href;
      // Send the URL to the backend
      axios.post("https://api.aibot.devateam.com/save-visit", { url: currentUrl });
    } catch (error) {
      console.error("Error saving visit details:", error);
    }
  };

  // Handle input change
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Handle sending text message
  const handleSendMessage = async () => {
    if (!inputValue.trim()) return;
    const userMessage = { sender: "user", text: inputValue.trim() };
    setConversation((prev) => [...prev, userMessage]);
    setLoading(true);
    setError(null);
    setInputValue("");

    try {
      if (isLiveSession && socket) {
        // Emit message via socket for live session
        socket.emit("send_message", inputValue.trim());
      } else {
        // Normal bot behavior
        const response = await fetch(`${apiUrl}/chat`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ message: inputValue.trim(), submitted }),
        });

        if (!response.ok) {
          throw new Error(`Server error: ${response.statusText}`);
        }

        const data = await response.json();
        const botMessage = {
          sender: "bot",
          text: data.response || "No response from server.",
        };

        setConversation((prev) => [...prev, botMessage]);
      }
    } catch (err) {
      console.error("Error:", err);
      setError("Failed to get response from server.");
    } finally {
      setLoading(false);
    }
  };

  // Handle contact form submission
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setFormLoading(true);
    // setFormMessage("");
    try {
      const response = await fetch(`${apiUrl}/send-email`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        throw new Error("Failed to send details.");
      }
      sessionStorage.setItem("submitted", true);
      const successMessage = "We Will Update You Soon";
      // setFormMessage(successMessage);
      setConversation((prev) => [
        ...prev,
        { sender: "system", text: successMessage },
      ]);
      // setFormData({ name: "", email: "", phone: "" });
    } catch (error) {
      console.error("Error:", error);
      // setFormMessage("Something Went Wrong");
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <div>
      {/* Chat Button */}
      <div className="a-bot-circle" onClick={togglePopup}>
        <IoLogoWechat className="a-bot-chaticon" />
      </div>
      {/* Chat Popup */}
      {showPopup && (
        <div className="a-bot-popup">
          <div className="a-bot-popup-content">
            <div className="a-bot-card">
              <div className="a-bot-chat-head">
                <h2 className="a-bot-title">Hi there</h2>
                <div className="a-bot-controller">
                  <button
                    onClick={livesession}
                    className={`a-bot-live-session ${
                      isActive ? "active" : ""
                    } ${!isActive ? "" : "disabled"}`}
                    aria-label="Live Session"
                    disabled={isActive} // Disable when the session is active
                  >
                    <RiCustomerServiceFill />
                  </button>
                  <button onClick={togglePopup} className="a-bot-closeicon">
                    <IoMdClose />
                  </button>
                </div>
              </div>
              <p className="a-bot-description">
                Need help? Search our help center or start a conversation
              </p>
            </div>
            <div className="a-bot-chat-window">
              <div className="a-bot-chat-messages" ref={chatMessagesRef}>
                {conversation.map((msg, index) => (
                  <div
                    key={index}
                    className={`a-bot-chat-message ${
                      msg.sender === "user"
                        ? "a-bot-user-message"
                        : msg.sender === "bot"
                        ? "a-bot-message"
                        : "a-bot-system-message"
                    }`}
                  >
                    {parser(msg.text)}
                    {msg.sender === "bot" &&
                    (msg.text.includes("with our agent now?") ||
                      msg.text.includes(
                        "you wanna connect with our agent now?"
                      ) ||
                      msg.text.includes("wanna connect with our agent")) ? (
                      <form>
                        <button
                          type="submit"
                          className="a-bot-userConnect-yes-button"
                          value="yes"
                          onClick={() => livesession(true)}
                        >
                          <BsHandThumbsUpFill className="a-bot-thumbs-up" />
                        </button>
                        <button
                          type="submit"
                          className="a-bot-userConnect-no-button"
                          value="no"
                          onClick={() => livesession(false)}
                        >
                          <BsHandThumbsDownFill className="a-bot-thumbs-down" />
                        </button>
                      </form>
                    ) : null}
                    {(submitted !== "Not Submitted" || submitted !== true) &&
                    msg.sender === "bot" &&
                    (msg.text.includes("Please fill out the form below") ||
                      msg.text.includes("and we will inform you soon.") ||
                      msg.text.includes("unfortunately live session") ||
                      msg.text.includes(
                        "so please fill out the form to connect with our agent later."
                      ) ||
                      msg.text.includes("our agent later")) ? (
                      <form onSubmit={handleFormSubmit}>
                        <label htmlFor="" className="a-bot-name-label">
                          Name
                        </label>
                        <input
                          type="text"
                          className="a-bot-contact-name"
                          name="name"
                          value={formData.name}
                          onChange={handleFormChange}
                          placeholder="Enter your name"
                          required
                        />
                        <label htmlFor="" className="a-bot-email-label">
                          Email
                        </label>
                        <input
                          type="email"
                          className="a-bot-contact-email"
                          name="email"
                          value={formData.email}
                          onChange={handleFormChange}
                          placeholder="Enter your email"
                          required
                        />
                        <label htmlFor="" className="a-bot-phone-label">
                          Phone
                        </label>
                        <input
                          type="number"
                          className="a-bot-contact-phone"
                          name="phone"
                          value={formData.phone}
                          onChange={handleFormChange}
                          placeholder="Enter your phone number"
                          required
                        />
                        {/* {formMessage} */}
                        <button
                          type="submit"
                          className="a-bot-form-send-button"
                          disabled={formLoading}
                        >
                          {formLoading ? "Sending..." : "Send"}
                        </button>
                      </form>
                    ) : null}
                  </div>
                ))}
                {loading && (
                  <div className="a-bot">
                    <TypingIndicator />
                  </div>
                )}
                {error && <div className="a-bot-error">{error}</div>}
              </div>

              <div className="a-bot-chat-input">
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  autoFocus
                  placeholder="Type a message..."
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && inputValue.trim() && !loading) {
                      handleSendMessage();
                    }
                  }}
                  disabled={loading}
                />
                <button
                  onClick={handleSendMessage}
                  className={`a-bot-send-icon ${
                    inputValue.trim() && !loading
                      ? "text-white brightness-100"
                      : "text-gray-500 brightness-75"
                  }`}
                  disabled={loading}
                >
                  <RiSendPlane2Fill />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
