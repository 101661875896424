import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import reportWebVitals from "./reportWebVitals";

// Detect an external container or fall back to the default 'root' container
const container =
  document.getElementById("netlify-app-container") ||
  document.getElementById("root");

// Create a root and render the app
if (container) {
  const root = ReactDOM.createRoot(container);
  root.render(
    <GoogleOAuthProvider clientId="749014427170-3q6kjjgklr3h450fs8ei5hveccj9kfcn.apps.googleusercontent.com">
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </GoogleOAuthProvider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
